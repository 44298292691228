
.commentItem{
    padding: 12px 20px 10px;


    .avator{
        margin-right: 8px;

        img{
            height: 24px;
            width: 24px;
            vertical-align: top;
            border-radius: 2px;
        }
    }

    .editTime{
        float: right;
        color: rgb(133, 144, 166);
        font-size: 14px;
    }

    .name{
        color: black;
        font-size: 15px;
        font-weight: 500;
    }
    .replay{
        margin-left: 8px;
        margin-right: 8px;
    }
    .commentWrap{
        padding-left: 33px;
        color: black;

        .comment{
            margin-bottom: 6px;
            line-height: 25px;
        }

    }

    .footer{
        margin-top: 4px;
        font-size: 14px;
        line-height: 24px;
        height: 24px;

        display: flex;
        align-items: center;
    }


    .btn{
        cursor: default;
        margin-left: 24px;
        flex-shrink: 0;
        background-color: transparent;
        border: none;
        font-size: 14px;
        line-height: 32px;
        color: #8590a6;
        text-align: center;

        display: flex;

        .icon{
            height: auto;
            margin-top: 3px;
            align-items: flex-end;
            margin-right: 4px;
        }
    }

    .btn:first-child{
        margin-left: 0 !important;
    }
    .btn:not(:first-child){
        opacity: 0;
        transition: all .3s;
    }
    // .btn:not(:first-child):hover{
    //     display: block;
    // }

}

.commentItem:hover{
    .btn{
        opacity: 1;
    }
}

.commentItem::after{
    content: "";
    display: block;
    border-bottom: 1px solid  rgb(246, 246, 246);
}   

