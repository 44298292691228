

    .author{                    
        display: flex;
        align-items: center;

        .avator{
            margin-top: 6px;
            height: 42px;
            width: 42px;
            border-radius: 2px;
            cursor: pointer;
        }

        .authorWrap{
            margin-left: 10px;

            .authorName{
                text-decoration: none;
                font-weight: 600;
                cursor: pointer;
                font-size: 16px;
                margin-bottom: 2px;
            }

            .authorHeadline{
                text-decoration: none;
                overflow: hidden;
                color: #646464;
                text-overflow: ellipsis;
                white-space: normal;
                font-size: 14px;
            }
        }


    }
