/*@import url('https://fonts.googleapis.com/css?family=Pirata+One|Rubik:900');*/
.logo {
    /*margin-top: -1.15em;*/
    font-family: 'Pirata One', cursive;
    font-size: 1.2rem;
    /*color: #F7971E;*/
    color: rgba(255, 161, 22, 1);
    /*color: rgba(0, 104, 217, 1);*/
    text-align: left;
}

.logo spam{
    color: rgba(0, 104, 217, 1);
}