.custom-tab {
    color: rgba(0, 0, 0, 0.6);
    align-items: center;
    justify-content: center;
    cursor: pointer;
    max-width: 360px;
    min-width: 90px;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: 0.02857em;
    min-height: 48px;
    text-transform: uppercase;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    padding: 0 16px;
}

.custom-tab-selected {
    color: rgba(0, 104, 217, 1)
}

