.table-row-title{
    padding-left: 10px;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
}
.table-cell-title{
    padding: 14px;
    line-height: 1.5rem;
    font-size: 0.875rem
}
.table-row{
    padding-left: 10px;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    margin: auto;
    min-height: 68px;
}
.table-cell{
    padding: 14px;
    margin: auto;
    line-height: 1.43;
    display: flex;
    /*实现垂直居中*/
    align-items: center;
}

.table-text{
    font-size: 0.98em;
}