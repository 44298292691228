.refresh-icon {
    animation: spin 1s infinite linear;
    -moz-animation: spin 1s infinite linear;
    -webkit-animation: spin 1s infinite linear;
    -o-animation: refreshing 1s infinite linear;
}

@-moz-keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@-webkit-keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@-o-keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}