

.wrapper{
    // border: 1px solid red;
    color: #646464;
    padding:10px 20px;
    margin:0 -20px;
    clear: both;

    display: flex;
    align-items: center;

    .btnWrapper{
        flex-shrink: 0;



        & button{
            outline: none;
            cursor: pointer;
            border: none;
            line-height: 30px;
            color: #0084ff;
            padding:0 12px;
            font-size: 14px;
            background: rgba(0,132,255,.1);
        }
        & button:hover{
            background-color: rgba(0,132,255,.15);
        }
        .vodeUp{
            width: 15px;
            margin-right: 4px;
        }
        .vodeDown{
            margin-left: 4px;
        }

        .btnVoteUp,.btnVoteDown{
            background-color: #0084ff;
            color: #fff;
        }
        .btnVoteUp:hover,.btnVoteDown:hover{
            background-color: #0084ff;
        }
        
    }

    .btn{
        // border: 1px solid red;
        outline: none;
        cursor: pointer;
        margin-left: 24px;
        flex-shrink: 0;
        background-color: transparent;
        border: none;
        font-size: 14px;
        line-height: 32px;
        color: #8590a6;
        text-align: center;

        display: flex;

        .commentWraper{
            display: flex;
        }

        .icon{
            // border: 1px solid green;
            height: auto;
            margin-top: 3px;
            align-items: flex-end;
            margin-right: 4px;
        }

    }
    .btn:hover{
        color: grey;
    }

    .btnShare,.btnLike,.btnCollect,.btnDots:hover{
        cursor: default;
    }

    .collapseBtn{
        margin-left: auto;
    }
}

.hidden{
    display: none;
}

.fix{
    width: 654px;
    background: #fff;
    box-shadow: 0 -1px 3px rgba(26,26,26,.1);
    position: fixed;
    bottom: 0px;

}