
.wrapper{
    background: #fff;
    width: 1010px;
    height: 100%;
    padding: 0 16px;
    margin:0 auto;

    .wrapperInner{
      .main{
            min-width: 694px;
            width: auto;
            padding-left: 0px;


            display: flex;
            align-items: center;
    
            .btnFollow{
                outline: none;
                background-color: #0084ff;
                color: #fff;
                display: inline-block;
                min-width: 96px;
                margin: 0 8px;
                padding:0 16px;
                font-size: 14px;
                line-height: 32px;
                border: 1px solid;
                border-radius: 3px;
            }
            .btnAnswer,.btnInvite,.btnGood{
                outline: none;
                color: #0084ff;
                border-color: #0084ff;
                margin: 0 8px;
                display: inline-block;
                background: none;
                padding:0 16px;
                font-size: 14px;
                line-height: 32px;
                border: 1px solid;
                border-radius: 3px;

                display: flex;
                span{
                    margin-top: 3px;
                    margin-right: 4px;
                    display: inline-flex;
                    align-items: center;
                }
            }
            .btnInvite{
                color: #8590a6;
                border-color: #8590a6;
            }

            .btnGood{
                color: #8590a6;
                border:none;
            }

            .btnComment,.btnShare,.btnDot,.btnUp{
                outline: none;
                cursor: default;
                background-color: transparent;
                border: none;
                font-size: 14px;
                line-height: 32px;
                color: #8590a6;
                text-align: center;

                display: flex;

                .icon{
                    height: auto;
                    margin-top: 3px;
                    align-items: flex-end;
                    margin-right: 4px;
                }
            }
            .btnComment{
                margin-left: -4px;
            }
            .btnShare{
                margin-left: 20px;

            }
            .btnDot{
                margin-left: 20px;

            }

            .collapseBtn{
                margin-left: 30px;
            }
            .btnUp{
                cursor: pointer;
            }
    }
    }
}


.fix{
    // border: 1px solid red;
    padding-top: 7px;
    padding-bottom: 7px;
    background: #fff;
    box-shadow: 0 -1px 3px rgba(26,26,26,.1);
    position: fixed;
    bottom: 0px;
    left:0px;
    max-width: inherit;
    width: 100%;


}